import React from 'react';
import { PathService } from '../../services';
import { PressRoomPostContext, TemplateProps } from '../../types';
import { PressRoomPostView } from '../../views';

const PressRoomPost: React.FunctionComponent<TemplateProps<
  PressRoomPostContext
>> = ({ pageContext, location }) => {
  PathService.set(location.pathname);
  return (
    <PressRoomPostView
      press={JSON.parse(pageContext.press)}
      relatedPress={JSON.parse(pageContext.relatedPress)}
    />
  );
};

export default PressRoomPost;
